import React, { useContext, useState } from "react"

import { AuthenticationContext } from "../../context/"
import Divider from "../UI/Divider"
import GoogleButton from "../UI/GoogleButton"

import "react-phone-input-2/lib/style.css"
import "./RegisterComponent.scss"

const RegisterComponent = () => {
  const initialValue = {
    email: "",
    firstName: "",
    lastName: "",
    mobile: "",
    password: "",
    postCode: "",
  }

  const { signUp, isLoadingRequest, setIsLoadingRequest } = useContext(
    AuthenticationContext
  )
  const [value, setValues] = useState(initialValue)

  const [validationText, setValidationText] = useState("Enter Mobile Number")

  const handleInputChange = (e) => {
    setIsLoadingRequest(false)
    const { name, value } = e.target
    let tempNum = value

    if (name === "mobile" && value.length > 10) {
      if (value[0] === "+") {
        tempNum = tempNum.slice(3, tempNum.length)
      } else {
        tempNum = tempNum.slice(0, 10)
      }
    }
    if (name === "mobile") {
      if (value.length < 10 && value[0] !== "0") {
        setValidationText("Mobile number should start with 0")
      } else if (value.length < 10 && value[0] === "0") {
        setValidationText("Enter valid mobile number")
      }
    }

    setValues((prev) => ({
      ...prev,
      [name]: name === "email" ? value.toLowerCase() : value,
      [name]: name === "mobile" ? tempNum : tempNum,
    }))
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 32) {
      e.preventDefault()
    }
  }

  const handleNumberInputKeyDown = (e) => {
    if (e.keyCode === 69) {
      e.preventDefault()
    }
  }
  const handleCharInputKeyDown = (e) => {
    if (
      !(e.key >= "0" && e.key <= "9") &&
      e.key !== "Backspace" &&
      e.key !== "Enter" &&
      e.key !== "ArrowLeft" &&
      e.key !== "ArrowRight"
    ) {
      e.preventDefault()
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const mobile = value.mobile
    const config = {
      confirm_success_url: `${process.env.GATSBY_REDIRECT_URL}/credit-card-payment`,
      email: value.email,
      first_name: value.firstName,
      last_name: value.lastName,
      mobile: mobile,
      password: value.password,
      password_confirmation: value.password,
      postcode: value.postCode,
    }
    signUp(config)
  }

  return (
    <div className="register-component-container">
      <div className="register-component">
        <div className="register-component__content">
          <div className="register-component__body">
            <div className="register-component__body__heading">
              <p>Register Account</p>
            </div>
            <div className="register-component__body__google">
              <GoogleButton text="Register with Google" />
            </div>
            <div className="register-component__body__divider">
              <Divider text="Or" />
            </div>
            <div className="register-component__body__form">
              <form
                className="register-form"
                id="register-form"
                onSubmit={handleSubmit}
              >
                <div className="register-form__item">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    id="firstName"
                    type="text"
                    name="firstName"
                    required
                    placeholder="Please Enter"
                    value={value.firstName}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="register-form__item">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    id="lastName"
                    type="text"
                    name="lastName"
                    required
                    placeholder="Please Enter"
                    value={value.lastName}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div className="register-form__item">
                  <label htmlFor="mobile">Mobile</label>
                  <input
                    id="mobile"
                    type="tel"
                    name="mobile"
                    required
                    placeholder="Please Enter"
                    value={value.mobile}
                    onChange={handleInputChange}
                    onKeyDown={handleCharInputKeyDown}
                    pattern="[0][0-9]{9}"
                    title={validationText}
                  />
                </div>
                <div className="register-form__item">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    required
                    placeholder="Please Enter"
                    value={value.email}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                    title="Please include a domain name in the email"
                  />
                </div>
                <div className="register-form__item">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    required
                    placeholder="Please Enter"
                    value={value.password}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="register-form__item">
                  <label htmlFor="postCode">Postcode</label>
                  <input
                    type="number"
                    name="postCode"
                    id="postCode"
                    required
                    placeholder="Please Enter"
                    value={value.postCode}
                    onChange={handleInputChange}
                    onKeyDown={handleNumberInputKeyDown}
                  />
                </div>
              </form>
            </div>
          </div>
          <div className="register-component__content__choose"></div>
        </div>
        <div className="register-component__submit">
          <button
            type="submit"
            form="register-form"
            disabled={isLoadingRequest}
          >
            Next Step
          </button>
        </div>
      </div>
      <div className="email-link">
        <a href="mailto:hello@wrlaundry.com">hello@wrlaundry.com</a>
      </div>
    </div>
  )
}

export default RegisterComponent

import React from "react"

import RegisterLayout from "../components/Layout/RegisterLayout"
import RegisterComponent from "../components/LoginRegister/RegisterComponent"
import "../components/LoginRegister/Authenticate.scss"

const register = () => {
  return (
    <RegisterLayout isFooter={false}>
      <RegisterComponent />
    </RegisterLayout>
  )
}

export default register
